// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-landing-js": () => import("./../../../src/pages/landing.js" /* webpackChunkName: "component---src-pages-landing-js" */),
  "component---src-pages-projects-adrian-steel-js": () => import("./../../../src/pages/projects/adrian-steel.js" /* webpackChunkName: "component---src-pages-projects-adrian-steel-js" */),
  "component---src-pages-projects-arctic-wolf-js": () => import("./../../../src/pages/projects/arctic-wolf.js" /* webpackChunkName: "component---src-pages-projects-arctic-wolf-js" */),
  "component---src-pages-projects-puente-js": () => import("./../../../src/pages/projects/puente.js" /* webpackChunkName: "component---src-pages-projects-puente-js" */),
  "component---src-pages-projects-safe-send-js": () => import("./../../../src/pages/projects/safe-send.js" /* webpackChunkName: "component---src-pages-projects-safe-send-js" */)
}

